import { render, staticRenderFns } from "./ComplexOverviewGasless.vue?vue&type=template&id=8565d522"
import script from "./ComplexOverviewGasless.vue?vue&type=script&lang=ts&setup=true"
export * from "./ComplexOverviewGasless.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports