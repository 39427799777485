import { render, staticRenderFns } from "./PercentageChart.vue?vue&type=template&id=66daa1c3"
import script from "./PercentageChart.vue?vue&type=script&lang=ts&setup=true"
export * from "./PercentageChart.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports