import { render, staticRenderFns } from "./BuildingParisProofScore.vue?vue&type=template&id=5f6c6192"
import script from "./BuildingParisProofScore.vue?vue&type=script&lang=ts&setup=true"
export * from "./BuildingParisProofScore.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports